import scrollToSection from './scrollToSection.js';

function aboutScrollingTabs() {
    let overviewY = document.querySelector('.sliderContainer').offsetTop - document.querySelector('.navContainer').clientHeight;
    let historyY = document.querySelector('.aboutTimeline').offsetTop - document.querySelector('.navContainer').clientHeight;
    let teamY = document.querySelector('.aboutTeam').offsetTop - document.querySelector('.navContainer').clientHeight;

    let scrollToOverview = scrollToSection(document.querySelector('.threeItemsSelector__item.first'), overviewY);
    let scrollToHistory = scrollToSection(document.querySelector('.threeItemsSelector__item.second'), historyY, true);
    let scrollToAbout = scrollToSection(document.querySelector('.threeItemsSelector__item.third'), teamY);
    let scrollToOverviewHint = scrollToSection(document.querySelector('.heroNext__cta'), overviewY);
}

export default aboutScrollingTabs;