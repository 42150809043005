function sliders() {
    $(document).ready(function(){
        // Main Slider
        var mainSlider = $('.hero .sliderContainer').bxSlider({
            mode: 'fade',
            auto: true,
            speed: 1000,
            pause: 9000,
            easing: 'ease-in-out',
            pager: false,
            onSlideNext: function($slideElement, oldIndex, newIndex) {
                $('.mainSlider .SlideSelector--item').removeClass('current');
                $('.mainSlider .SlideSelector--item:nth-child('+ (newIndex+1) +')').addClass('current');
            }
        });

        function goToSlideButtons(selector, slideNumber) {
            $(selector + ':nth-child('+ (slideNumber + 1) +')').on('click', () => {
                $(selector).removeClass('current');
                $(selector + ':nth-child('+ (slideNumber + 1) +')').addClass('current');
                mainSlider.goToSlide((slideNumber));
            });
        }

        var z;
        var numberOfSlides = 4;

        for(z = 0; z < numberOfSlides; z++) {
            goToSlideButtons('.mainSlider .SlideSelector--item', z);
        }

        // Testimonial Slider
        var testimonialSlider = $('.testimonialSlider').bxSlider({
            easing: 'ease-in-out',
            adaptiveHeight: true,
            pager: false,
            slideMargin: 10,
            auto: true,
            speed: 1000,
            pause: 6000,
            touchEnabled: false
        });

        $('.testimonialSlide__arrowNext').on('click', function() {
            testimonialSlider.goToNextSlide();
        });

        $('.testimonialSlide__arrowPrev').on('click', function() {
            testimonialSlider.goToPrevSlide();
        });

        // Ticker Logos
        var tickerLogos = $('.tickerLogos').bxSlider({
            easing: 'ease-in-out',
            pager: false,
            ticker: true,
            speed: 40000,
            minSlides: 4,
            maxSlides: 4,
            slideWidth: 300,
            slideMargin: 40
        });

        // About
        var aboutSlider = $('.animatedSliderComponent ul').bxSlider({
            auto: false,
            speed: 800,
            pager: false,
            infiniteLoop: true,
            onSlideBefore: function(slideElement, oldIndex, newIndex) {
                $('.animatedSliderComponent__animator:not(.bx-clone)').fadeTo(400, 0.3);
                setTimeout(function() {
                    slideElement.find('.animatedSliderComponent__animator').fadeTo(400, 1);
                }, 500);
            }
        });

        $('.controls__right').on('click', function() {
            aboutSlider.goToNextSlide();
        });

        $('.controls__left').on('click', function() {
            aboutSlider.goToPrevSlide();
        });
    });
}

export default sliders;