// Class Toggler

var ClassToggler = function (args) {
    this.elements = document.querySelectorAll(args.elements);
    this.shooter = document.querySelectorAll(args.shooter);
    this.openClass = args.openClass;
    this.overlay = args.overlay;

    this.open = function () {
        for (var i = 0; i < this.elements.length; i++) {
            this.elements[i].classList.add(this.openClass);
        }
    };

    this.close = function () {
        for (var i = 0; i < this.elements.length; i++) {
            this.elements[i].classList.remove(this.openClass);
        }
    };

    this.togglefy = function () {
        var overlayElement = document.getElementById('backOverlay');

        for (var i = 0; i < this.shooter.length; i++) {
            this.shooter[i].addEventListener('click', function (e) {
                e.preventDefault();
                if (this.elements[0] && this.elements[0].classList.contains(this.openClass)) {
                    if (this.overlay == true) {
                        overlayElement.classList.remove(this.openClass);
                    }
                    this.close();
                } else {
                    if (this.overlay == true) {
                        overlayElement.style.pointerEvents = "all";
                        overlayElement.classList.add(this.openClass);
                    }
                    this.open();
                }
            }.bind(this));
        }

        if (this.overlay == true) {
            overlayElement.addEventListener('click', function (e) {
                e.preventDefault();
                overlayElement.classList.remove(this.openClass);
                overlayElement.style.pointerEvents = "none";
                this.close();
            }.bind(this));
        }
    };
};

export default ClassToggler;