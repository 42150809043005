import { isHomepage, isAboutPage, isServices } from './vars/globals.js';
import InputFocuser from './inputFocuser.js';
import ClassToggler from './classToggler.js';
import aboutScrollingTabs from './scrollEvents.js';
import timelineAnimation from './timelineAnimation.js';
import ServicesFinder from './servicesFinder.js';
import sliders from './sliders.js';
import documentsTabSelectors from './documentsAjax.js';
import teamModal from './teamModal.js';
import animate from './animate.js';
import homepageFunctions from './homepageFunctions.js';
import servicesTiles from './servicesTiles.js';
import newsletterSubscription from './newsletter.js';
import contactForm from './contactForm.js';

if (!String.prototype.includes) {
     String.prototype.includes = function() {
         'use strict';
         return String.prototype.indexOf.apply(this, arguments) !== -1;
     };
 }

contactForm();

// Team Modal Toggler
var timelineModal01 = new ClassToggler({
     elements: '.aboutOverlay, #modal01',
     shooter: '.timeline a.modal01, .modal01Close',
     openClass: 'active'
 }).togglefy();

var timelineModal02 = new ClassToggler({
    elements: '.aboutOverlay, #modal02',
    shooter: '.timeline a.modal02, .modal02Close',
    openClass: 'active'
}).togglefy();

var timelineModal03 = new ClassToggler({
    elements: '.aboutOverlay, #modal03',
    shooter: '.timeline a.modal03, .modal03Close',
    openClass: 'active'
}).togglefy();

var timelineModal04 = new ClassToggler({
    elements: '.aboutOverlay, #modal04',
    shooter: '.timeline a.modal04, .modal04Close',
    openClass: 'active'
}).togglefy();

var timelineModal035 = new ClassToggler({
    elements: '.aboutOverlay, #modal05',
    shooter: '.timeline a.modal05, .modal05Close',
    openClass: 'active'
}).togglefy();

var privacyModal = new ClassToggler({
    elements: '.securityAndPrivacyModal, .privacyOverlay',
    shooter: '.securityAndPrivacy',
    openClass: 'active'
}).togglefy();

var privacyModalClose = new ClassToggler({
    elements: '.securityAndPrivacyModal, .privacyOverlay',
    shooter: '.securityAndPrivacy__close, .privacyOverlay',
    openClass: 'active'
}).togglefy();

// Input Focus Effect
let inputFocuser = new InputFocuser('.kabelForm input, .kabelForm textarea').init();

// ServicesFinder Component
new ServicesFinder().init();

// Contact Form Autosize
autosize(document.querySelector('.kabelForm textarea'));

// Sliders
sliders();

// Documents Ajax calls
documentsTabSelectors();

// Team Modal
teamModal();

// Animate body is hidden when loaded
window.addEventListener("load", function load() {
    animate();
    homepageFunctions();

    if(isAboutPage) {
        aboutScrollingTabs();
    }
    if(isServices) {
        servicesTiles();
    }

}, false);

/////////////////////////////////////
// Hamburguer Menu //////////////////
/////////////////////////////////////
window.onload = function() {
    var navMobile = document.getElementById('nav--mobile');
    var mobileHamburgerIcon = document.getElementById('mobileHamburgerIcon');
    var mobileCloseIcon = document.getElementById('mobileCloseIcon');

    mobileHamburgerIcon.addEventListener('click', function() {
        navMobile.classList.add('open');
        mobileHamburgerIcon.classList.add('open');
    });

    mobileCloseIcon.addEventListener('click', function() {
        navMobile.classList.remove('open');
        mobileHamburgerIcon.classList.remove('open');
    });
};

newsletterSubscription();