function teamModal() {
    let aboutOverlay = $('.aboutOverlay');
    let aboutModal = $('.aboutModal');
    let spinner = $('.spinner');
    let timeLineModal = $('.row__popup');

    $('.teamBlock__readMore').on('click', e => {
        e.preventDefault();
    });

    $(document).on('click', '.teamBlock.teamMember', function(event) {
        
        let link = $(this).find('.teamBlock__readMore').attr('href');
        if( aboutOverlay.hasClass('active') || aboutModal.hasClass('active') ) {
            aboutOverlay.removeClass('active');
            aboutModal.removeClass('active');
            aboutModal.removeAttr('style');
        } else {
            aboutOverlay.addClass('active');
            spinner.fadeIn();

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                method: "POST",
                url: link,
                success: function(data){
                    aboutModal.find('.teamBlock').html(data);
                    if(aboutModal.height() < $(window).height()) {
                        aboutModal.addClass('active').css('top', ( $(window).scrollTop() + (($(window).height() - aboutModal.height()) / 2) ));
                    } else {
                        aboutModal.addClass('active').css('top', $(window).scrollTop());
                    }
                }
            }).done(function( msg ) {
                spinner.fadeOut();
                aboutModal.find('.teamBlock').css('opacity', 1);
                $("a[data-popup]").on('click', function(e) {
                    e.preventDefault();
                    window.open($(this)[0].href);
                });
            });
        }

        return false;
        
    });

    $(document).on('click', '.aboutModalClose, .aboutOverlay', function() {
        aboutOverlay.removeClass('active');
        aboutModal.removeClass('active');
        aboutModal.find('.teamBlock').css('opacity', 0);
        timeLineModal.removeClass('active');
    });
}

export default teamModal;