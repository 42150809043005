import scrollToY from './functions/scrollToY.js';
import { slideDown, slideUp } from './functions/slide.js';

function scrollToSection(shooter, destination, history = false) {
    shooter.addEventListener('click', e => {
        e.preventDefault();
        scrollToY(destination, 800, 'easeInOutQuint');

        let timeline = document.querySelector('.timelineContainer');
        let timelineShooter = document.querySelector('.shooter');
        let shooterArrow = timelineShooter.querySelector('.zmdi');

        if(history == true) {
            setTimeout(function() {
                timelineShooter.classList.add('open');
                slideDown(timeline);
                shooterArrow.classList.add('open');
            }, 800);

            return false;
        }

        timelineShooter.classList.remove('open');
        slideUp(timeline);
        shooterArrow.classList.remove('open');
    });
}

export default scrollToSection;