// Globals common vars
let body = document.getElementsByTagName('body')[0], html = document.documentElement;
let documentHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
let windowHeight = window.innerHeight;

// Constants
const isAboutPage = document.querySelector('.aboutOverview');
const isHomepage = document.querySelector('.logosSection');
const isServices = document.querySelector('.servicesBoxes');

export {
    documentHeight,
    windowHeight,
    isAboutPage,
    isHomepage,
    isServices
};