function servicesTiles() {
    var $grid = $('.servicesBoxes').isotope({
        itemSelector: '.serviceBox',
        layoutMode: 'masonry',
        percentPosition: true,
        masonry: {
            columnWidth: '.serviceBox'
        },
        fitWidth: true
    });

    let container = $('.serviceBox__container');
    let initial = '.serviceDescription.initial';
    let expanded = '.serviceDescription.expanded';
    let initialChoosenTags = '.choosenTags';
    let expandedChoosenTags = '.choosenTagsExpanded';

    container.find('.zmdi').on('click', function() {
        let _serviceBox = $(this).parent();
        let _self = $(this).parent().parent();
        let $this = $(this);

        if($(this).hasClass('zmdi-plus-circle')) {
            open();
            return false;
        }

        close();

        function open() {
            _self.parent().addClass('open');
            $this.removeClass('zmdi-plus-circle');
            $this.addClass('zmdi-minus-circle');
            _self.find(initialChoosenTags).fadeOut();

            setTimeout(function() {
                _self.find(initial).removeClass('active');
            }, 400);
            setTimeout(function() {
                _self.find(initial).removeClass('show');
            }, 700);
            setTimeout(function() {
                _self.find(expanded).addClass('show');
            }, 800);
            setTimeout(function() {
                _self.find(expanded).addClass('active');
                _self.find(expandedChoosenTags).fadeIn();
                $grid.isotope('layout');
            }, 1000);
            setTimeout(function() {
                let currentBoxHeight = _serviceBox.outerHeight();
                _serviceBox.css('min-height', currentBoxHeight);
            }, 1300);
        }

        function close() {
            $this.removeClass('zmdi-minus-circle');
            $this.addClass('zmdi-plus-circle');

            _self.find(expandedChoosenTags).fadeOut();
            setTimeout(function() {
                _self.find(expanded).removeClass('active');
            }, 400);
            setTimeout(function() {
                _self.find(expanded).removeClass('show');
            }, 700);
            setTimeout(function() {
                _serviceBox.removeAttr('style');
            }, 800);
            setTimeout(function() {
                _self.find(initial).addClass('active');
                $grid.isotope('layout');
            }, 1000);
            setTimeout(function() {
                _self.find(initial).addClass('show');
                _self.find(initialChoosenTags).fadeIn();
                $grid.isotope('layout');
            }, 1300);
            setTimeout(function() {
                _self.parent().removeClass('open');
            }, 1500);
        }
    });
}

export default servicesTiles;