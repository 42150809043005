function animate() {
    let _body = document.body;

    if(_body.classList.contains('hide')) {
        setTimeout(function() {
            _body.classList.remove('hide');
        }, 400);
    }
}

export default animate;