import scrollToY from './functions/scrollToY.js';

function homepageFunctions() {

    if(document.getElementById('servicesBanner')) {
        let servicesBanner = document.getElementById('servicesBanner').offsetTop;

        document.querySelector('.heroNext__cta').addEventListener('click', function(e) {
            e.preventDefault();

            scrollToY((servicesBanner-196), 600, 'easeInOutSine');
        });
    }
}

export default homepageFunctions;