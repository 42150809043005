function newsletterSubscription() {
    $('.newsletterSubscription').on('submit', function(e) {
        e.preventDefault();

        let data = $('.newsletterSubscription').serialize();

        $('.newsletterSubscription').find('.submit--green').addClass('disabled');

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            method: "POST",
            data: data,
            url: "/newsletter-subscription"
        }).done(function(msg) {
            $('.newsletterSubscription').css('opacity', 0);
            setTimeout(function() {
                $('.newsletterSubscription').html('<p class="openSans--R20">Thank you. You have been subscribed.</p>');
            }, 400);
            setTimeout(function() {
                $('.newsletterSubscription').removeAttr('style');
            }, 800);
        });
    });
}

export default newsletterSubscription;