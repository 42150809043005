function contactForm() {
    $('.kabelForm .btn').on('click', function(e) {
        e.preventDefault();

        let data = $('.kabelForm').serialize();

        let name = $('input#name');
        let lastname = $('input#last-name');
        let email = $('input#email');
        let number = $('input#contact-number');

        if(name.val() == '') {
            name.addClass('error');
            return false;
        }

        if(lastname.val() == '') {
            lastname.addClass('error');
            return false;
        }

        if(email.val() == '') {
            email.addClass('error');
            return false;
        }

        if(number.val() == '') {
            number.addClass('error');
            return false;
        }

        $('.kabelForm').find('.btn--cyan').addClass('disabled');

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            method: "POST",
            data: data,
            url: "/contact"
        }).done(function(msg) {
            $('.kabelForm').css('opacity', 0);
            setTimeout(function() {
                $('.kabelForm').html('<p class="openSans--R20">Thank you. Your message has been sent.</br> We will get back to you shortly.</p>');
            }, 400);
            setTimeout(function() {
                $('.kabelForm').removeAttr('style');
            }, 800);
        });
    });
}

export default contactForm;