// Input Hoverer

class InputFocuser {
    constructor(input) {
        this.input = document.querySelectorAll(input);
    }

    init() {
        this.focus();
        this.unFocus();
    }

    focus() {
        this.focuser('focus', 'add');
    }

    unFocus() {
        this.focuser('blur', 'remove');
    }

    focuser(event, action) {
        let _inputs = this.input;

        for (let i = 0; i < _inputs.length; i++) {
            let parent = _inputs[i].parentNode;
            _inputs[i].addEventListener(event, e => {
                e.preventDefault();
                if(this.isEmpty(_inputs[i])) {
                    this.removeFocus();
                    if(action == 'remove') {
                        parent.classList.remove('active');
                    } else {
                        parent.classList.add('active');
                    }
                }
            });
        }
    }

    removeFocus() {
        let _inputs = this.input;
        // Removes the active class for all the empty inputs
        for (let i = 0; i < _inputs.length; i++) {
            if(this.isEmpty(_inputs[i])) {
                _inputs[i].parentNode.classList.remove('active');
            }
        }
    }

    isEmpty(element) {
        if(element.value.length == 0) {
            return true;
        }
        return false;
    }
}


export default InputFocuser;