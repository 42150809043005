function documentsTabSelectors() {
    let ajaxContainer = $('.ajaxContainer');
    let connectTab = $('.threeItemsSelector__item:nth-child(1)');
    let newsFlashTab = $('.threeItemsSelector__item:nth-child(2)');
    let newsletterTab = $('.threeItemsSelector__item:nth-child(3)');

    tabs(connectTab, ajaxContainer, 'connect');
    tabs(newsFlashTab, ajaxContainer, 'newsflash');
    tabs(newsletterTab, ajaxContainer, 'newsletter');
}

function tabs(selector, container, url) {
    selector.on('click', () => {
        container.addClass('transitioning');
        $('.loadingSpinner').fadeIn(300);

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            method: "POST",
            url: "/" + url
        }).done(function( msg ) {
            $('.loadingSpinner').fadeOut(300, () => {
                container.html(msg);
                setTimeout(() => {
                    container.removeClass('transitioning');
                }, 500);
            });
        });
    });
}

export default documentsTabSelectors;