import { slideDown, slideUp } from './functions/slide.js';

function timelineAnimation() {
    // Timeline Animation
    if( document.querySelector('.timelineContainer') ) {
        let timeline = document.querySelector('.timelineContainer');
        let timelineShooter = document.querySelector('.shooter');
        let shooterArrow = timelineShooter.querySelector('.zmdi');

        timelineShooter.addEventListener('click', e => {
            e.preventDefault();

            if( !timelineShooter.classList.contains('open') ) {
                timelineShooter.classList.add('open');
                slideDown(timeline);
                shooterArrow.classList.add('open');
            } else {
                timelineShooter.classList.remove('open');
                slideUp(timeline);
                shooterArrow.classList.remove('open');
            }
        });
    }
}

export default timelineAnimation();