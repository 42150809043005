import { slideDown, slideUp } from './functions/slide.js';
import scrollToY from './functions/scrollToY.js';

class ServicesFinder {

    constructor() {
        this.steps = document.querySelectorAll('.servicesSlide');
        this.overlay = document.querySelector('.windowOverlay');
        this.stepContainer = document.querySelector('.servicesStep');
        this.stepSelectors = document.querySelectorAll('.servicesStep__step');
        this.boxes = document.querySelectorAll('.optionsBoxes__box');
        this.selectionsModal = document.querySelector('.selectionsModal');
        this.servicesHero = document.querySelector('.servicesHero');
    }

    openModal() {
        let i,
            overlay = this.overlay,
            steps = this.steps;

        for(i = 0; i < steps.length; i++) {
            let modal = steps[i].querySelector('.servicesSlide__overlay');
            let modalOptions = steps[i].querySelectorAll('.options');
            let shooter = steps[i].querySelector('.servicesPhrase .selected');
            let currentStep = steps[i];

            if(shooter) {
                shooter.addEventListener('click', e => {
                    e.preventDefault();

                    overlay.style.display = 'block';
                    setTimeout( () => {
                        overlay.classList.add('active');
                        modal.classList.add('active');
                    }, 300);
                });
            }

            this.grabModalOption(modalOptions, modal, currentStep, i);
        }
    }

    grabModalOption(modalOptions, modal, currentStep, currentStepNumber) {
        let i;
        let selectionModal = this.selectionsModal;
        let steps = document.querySelectorAll('.servicesStep__step');

        for(i=0; i < modalOptions.length; i++) {
            let _item = modalOptions[i];

            _item.addEventListener('click', e => {
                e.preventDefault();
                // Grab option text
                let selectedOptionText = _item.textContent;

                // Change next button text to finish if other than "personal" has been selected
                if( currentStepNumber == 0 ) {
                    if(selectedOptionText.indexOf('personal') == -1) {
                        currentStep.querySelector('.nextButton').innerHTML = 'Finish';
                        steps[1].classList.add('disabled');
                        steps[2].classList.add('disabled');
                        if(currentStep.classList.contains('modified')) {
                            this.goToStep(this.steps[0], 0);
                        }
                    } else {
                        currentStep.querySelector('.nextButton').innerHTML = 'Next';
                        steps[1].classList.remove('disabled');
                        steps[2].classList.remove('disabled');
                    }
                }

                // Change previously selected keyword for the current one
                currentStep.querySelector('.servicesPhrase .selected').textContent = selectedOptionText;
                if(currentStepNumber == 0) {
                    selectionModal.querySelectorAll('.selectionsModal__step')[currentStepNumber].innerHTML = 'I am looking for <span class="pictonBlue">' + selectedOptionText + '</span> advice<i class="zmdi pictonBlue zmdi-caret-down"></i>';
                } else {
                    selectionModal.querySelectorAll('.selectionsModal__step')[currentStepNumber].innerHTML = 'I am <span class="pictonBlue">' + selectedOptionText + '</span><i class="zmdi pictonBlue zmdi-caret-down"></i>';
                    document.querySelector('.servicesBanner').setAttribute('data-selection', selectedOptionText);
                }
                // Deselect previously selected option in modal
                this.resetModalOptionSelection(modalOptions);
                // Select new chosen option in modal
                _item.classList.add('selected');
                // Close modal
                setTimeout(() => {
                    this.closeModal(modal);
                }, 400);
                // Clean inline styles if options popup has been clicked
                if(currentStep.classList.contains('modified')) {
                    currentStep.classList.remove('modified');
                    currentStep.querySelector('.servicesPhrase').style.display = 'block';
                    currentStep.querySelector('.nextButton').style.display = 'block';
                    currentStep.style.opacity = 0;
                    currentStep.style.display = 'none';
                    currentStep.querySelector('.servicesSlide__overlay').classList.remove('active');
                }
            });
        }
    }

    resetModalOptionSelection(modal) {
        let i;

        for(i=0; i < modal.length; i++) {
            let _item = modal[i];
            _item.classList.remove('selected');
        }
    }

    hideServicesHero() {
        let hero = this.servicesHero;
        setTimeout(() => {
            hero.classList.add('hide');
        }, 100);
        setTimeout(() => {
            scrollToY(0, 400, 'easeOutSine');
        }, 400);
        setTimeout(() => {
            slideUp(hero, '160px');
        }, 800);
    }

    closeModalOnClick() {
        let i,
            overlay = this.overlay,
            steps = this.steps;

        for(i = 0; i < steps.length; i++) {
            let modal = steps[i].querySelector('.servicesSlide__overlay');

            if(modal) {
                overlay.addEventListener('click', e => {
                    e.preventDefault();
                    this.closeModal(modal);
                });
            }
        }
    }

    closeModal(modal) {
        let overlay = this.overlay;

        overlay.classList.remove('active');
        modal.classList.remove('active');
        setTimeout( () => {
            overlay.style.display = 'none';
        }, 300);
    }

    selectStep() {
        let i,
            selectors = this.stepSelectors,
            slides = this.steps;

        for(i = 0; i < selectors.length; i++) {
            let _selector = selectors[i];
            let _slide = slides[i];
            let _slideNumber = i;

            _selector.addEventListener('click', e => {
                e.preventDefault();
                // Show top indicator with steps
                if( _slideNumber == 0 ) {
                    this.stepContainer.classList.add('initialPos');
                    this.stepContainer.classList.remove('secondPos');
                    this.stepContainer.classList.remove('thirdPos');
                }
                if( _slideNumber == 1 ) {
                    this.showIndicatorStep(0);
                }
                if( _slideNumber == 2 ) {
                    this.selectionsModal.classList.add('complete');
                    setTimeout(() => {
                        this.showIndicatorStep(1);
                    }, 300);
                }
                // Select step
                this.removeCurrentStateToAll();
                this.highlightCurrentState(_selector);
                this.showSlide(_slide);
            });

        }
    }

    goToStep(step, stepNumber) {
        let selectors = this.stepSelectors;

            // Select step
            this.removeCurrentStateToAll();
            this.highlightCurrentState(selectors[stepNumber]);
            this.showSlide(step);

        if(stepNumber == 0) {
            // Show step indicator at position 1
            this.stepContainer.classList.add('initialPos');
            this.stepContainer.classList.remove('secondPos');
            this.stepContainer.classList.remove('thirdPos');
            // Hide selections modal
            this.selectionsModal.style.display = 'none';
            if(this.servicesHero) {
                slideUp(this.servicesHero, '0');
            }
        }
    }

    nextStep() {
        let i,
            steps = this.steps,
            selectors = this.stepSelectors;

        for(i=0; i < steps.length; i++) {
            let nextButton = steps[i].querySelector('.nextButton');
            let _selector = selectors[i + 1];
            let _slide = steps[i + 1];
            let _currentSlide = steps[i];
            let _slideNumber = i;
            let _selectedOptionText;

            nextButton.addEventListener('click', e => {
                e.preventDefault();

                // Get selected option in the first two slides
                if(_slideNumber != 2) {
                    _selectedOptionText = _currentSlide.querySelector('.servicesSlide__overlay .options.selected').textContent;
                }
                // Initial Slide
                if( _slideNumber == 0 ) {
                    if(_selectedOptionText.includes('personal')) {
                        this.showIndicatorStep(0);
                    } else {
                        this.redirectToServicesPage('options');
                    }
                }
                // Second Slide
                if( _slideNumber == 1 ) {
                    this.selectionsModal.classList.add('complete');
                    this.showIndicatorStep(1);
                }
                // If it's the last slide
                if( _slideNumber == 2 ) {
                    this.redirectToServicesPage('options');
                }
                // Select step
                this.removeCurrentStateToAll();
                setTimeout(() => {
                    this.highlightCurrentState(_selector);
                }, 300);
                this.showSlide(_slide);
            });
        }
    }

    redirectToServicesPage(options) {
        document.body.classList.add('hide');

        if(options == 'all') {
            setTimeout(() => {
                window.location = "/services/all";
            }, 800);

            return false;
        }

        this.sendAjax();
    }

    showIndicatorStep(nro) {
        let indicators = this.selectionsModal.querySelectorAll('.selectionsModal__step');
        indicators[nro].style.display = 'block';
        setTimeout(() => {
            this.selectionsModal.style.display = 'block';
        }, 200);
        setTimeout(() => {
            this.selectionsModal.classList.remove('inactive');
            indicators[nro].classList.add('active');
            if(nro == 0) {
                this.stepContainer.classList.add('secondPos');
                this.stepContainer.classList.remove('initialPos');
                this.stepContainer.classList.remove('thirdPos');
                /*if(this.servicesHero) {
                    this.hideServicesHero();
                }*/
            }
            if(nro == 1) {
                this.stepContainer.classList.add('thirdPos');
                this.stepContainer.classList.remove('initialPos');
                this.stepContainer.classList.remove('secondPos');
            }
        }, 400);
    }

    removeCurrentStateToAll() {
        let i,
            selectors = this.stepSelectors;

        for(i = 0; i < selectors.length; i++) {
            selectors[i].classList.remove('current');
        }
    }

    highlightCurrentState(el) {
        el.classList.add('current');
    }

    showSlide(el) {
        let i,
            steps = this.steps;

        // Hide all slides
        for(i = 0; i < steps.length; i++) {
            let _step = steps[i];
            _step.style.opacity = 0;
            setTimeout(() => {
                _step.style.display = 'none';
            }, 300);
        }

        // Show desired slide
        setTimeout(() => {
            el.style.display = 'block';
        }, 300);
        setTimeout(() => {
            el.style.opacity = 1;
        }, 400);
    }

    selectBox() {
        let i,
            boxes = this.boxes;

        for(i = 0; i < boxes.length; i++) {
            let _box = boxes[i];

            _box.addEventListener('click', e => {
                e.preventDefault();
                if(_box.classList.contains('selected')) {
                    _box.classList.remove('selected');
                } else {
                    _box.classList.add('selected');
                }
            });
        }
    }

    changeSelectionsModalOptions() {
        let i,
            selectionModalSteps = document.querySelectorAll('.selectionsModal__step'),
            slides = this.steps,
            overlay = this.overlay;

        for(i=0; i < selectionModalSteps.length; i++) {
            let _slide = slides[i];

            selectionModalSteps[i].addEventListener('click', e => {
                e.preventDefault();
                // Show the popup
                _slide.classList.add('modified');
                _slide.querySelector('.servicesPhrase').style.display = 'none';
                _slide.querySelector('.nextButton').style.display = 'none';
                _slide.style.opacity = 1;
                _slide.style.display = 'block';
                overlay.style.display = 'block';
                setTimeout(e => {
                    overlay.classList.add('active');
                    _slide.querySelector('.servicesSlide__overlay').classList.add('active');
                }, 300);
            });
        }
    }

    sendAjax() {
        let i;
        let _slide = this.steps[2];
        let _choosenCategory = this.selectionsModal.querySelector('.selectionsModal__step:nth-child(1) .pictonBlue').innerText;
        let _choosenSubcategory = this.selectionsModal.querySelector('.selectionsModal__step:nth-child(2) .pictonBlue').innerText;
        let _tagsContainer = document.querySelectorAll('.optionsBoxes__box.selected');
        let _tags = [];

        for(i=0; i < _tagsContainer.length; i++) {
            _tags.push(_tagsContainer[i].innerText);
        }

        if(_tags.length > 1) {
            if(_choosenCategory.indexOf('mortgage') > -1) {
                return window.location = '/services?cat=' + _choosenCategory.replace(/\s+/g, '');
            } else if(_choosenCategory.indexOf('business') > -1) {
                return window.location = '/services?cat=' + _choosenCategory.replace(/\s+/g, '');
            } else {
                return window.location = '/services?cat=' + _choosenCategory.replace(/\s+/g, '') + '&subcat=' + _choosenSubcategory + '&tags=' + _tags;
            }
        } else {
            if(_tags.indexOf('None of the above') > -1) {
                return window.location = '/services/all?search=true';
            } else {
                if(_choosenCategory.indexOf('mortgage') > -1) {
                    return window.location = '/services?cat=' + _choosenCategory.replace(/\s+/g, '');
                } else if(_choosenCategory.indexOf('business') > -1) {
                    return window.location = '/services?cat=' + _choosenCategory.replace(/\s+/g, '');
                } else {
                    return window.location = '/services?cat=' + _choosenCategory.replace(/\s+/g, '') + '&subcat=' + _choosenSubcategory;
                }
            }
        }
    }

    init() {
        this.openModal();
        this.closeModalOnClick();
        this.selectStep();
        this.selectBox();
        this.nextStep();
        this.changeSelectionsModalOptions();
    }
}

export default ServicesFinder;